@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 0.75rem !important;
  padding-right: 1px;
  border-radius: 0.25rem !important;
}

@layer base {
  body {
    @apply bg-slate-100 min-h-full;
  }
}

@layer components {
  .dropdown,
  .input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary block w-full px-2 py-2;
    @apply focus:outline-none;
  }

  .chart {
    @apply bg-white shadow-sm rounded-sm p-2 h-full;
  }
}
